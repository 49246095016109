<template>
<div v-loading="loading" element-loading-text="拼命加载中">
  <div>
    <el-alert title="数据采集时间: " class="my-3" type="info" :closable="false" show-icon>{{updatedAt}}</el-alert>
    <div class="bg-white px-3 pt-3 pb-3">
      <el-date-picker size="medium" v-model="selectTime" type="daterange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy 年 MM 月 dd 日" value-format="timestamp"></el-date-picker>
      <el-button type="primary" size="medium" @click="getData">查询</el-button>
      <el-button type="primary" size="medium" icon="el-icon-download" @click="exportData">导出数据</el-button>
    </div>
    <div class="bg-white px-3 pt-3">
      <div slot="header" class="d-flex justify-content-between">
        <p class="font-weight-bold text-muted mb-3">用户统计</p>
        <el-select v-model="sortValue" size="medium" @change="sortChange">
          <el-option label="TOP10 完成" value="total"></el-option>
          <el-option label="TOP10 合格率" value="qualifiedrate"></el-option>
          <el-option label="TOP10 不合格率" value="unqualifiedrate"></el-option>
        </el-select>
      </div>
      <ve-histogram :data="chartDataSlice()" :settings="chartSettings" :colors="chartColors" :data-empty="dataEmpty"></ve-histogram>
    </div>
    <div class="box-card bg-white p-3 mb-4">
      <p class="font-weight-bold text-muted mb-3">用户详情</p>
      <div class="filter-container mb-2">
        <el-input v-model="search" size="medium" placeholder="输入用户名搜索" style="width:20%">
          <i slot="suffix" class="el-input__icon el-icon-search"></i>
        </el-input>
      </div>
      <el-table :data="tableData.filter(data => !search || data.username.toLowerCase().includes(search.toLowerCase()))" border>
        <el-table-column prop="username" label="用户名"></el-table-column>
        <el-table-column prop="teamid" label="组号"></el-table-column>
        <el-table-column prop="total" label="完成"></el-table-column>
        <el-table-column prop="qualified" label="合格"></el-table-column>
        <el-table-column prop="oncequalified" label="一次合格"></el-table-column>
        <el-table-column prop="twicequalified" label="两次合格"></el-table-column>
        <el-table-column prop="unqualified" label="不合格"></el-table-column>
        <el-table-column prop="notreviewed" label="未审核"></el-table-column>
        <el-table-column prop="qualifiedrate" label="合格率"></el-table-column>
        <el-table-column prop="unqualifiedrate" label="不合格率"></el-table-column>
      </el-table>
    </div>
  </div>
</div>
</template>

<script>
import XLSX from 'xlsx'
import { usersDetails } from '@/api/report-task'
import VeHistogram from 'v-charts/lib/histogram.common'
import { sheet2blob, openDownloadDialog } from '@/lib/print/exportExcel'

export default {
  name: 'table-user',
  components: {
    VeHistogram
  },
  data () {
    this.chartSettings = {
      labelMap: {
        total: '完成数',
        qualified: '合格数',
        unqualified: '不合格数'
      }
    }
    this.chartColors = ['#5AB1EF', '#19D4AE', '#FA6E86']
    return {
      num1001: 0,
      sortValue: 'total',
      chartData: {
        columns: ['username', 'total', 'qualified', 'unqualified'],
        rows: []
      },
      dataEmpty: true,
      search: '',
      tableData: [],
      updatedAt: null,
      loading: true,
      selectTime: []
    }
  },
  created () {
    const vm = this
    // 默认显示时间
    const start = new Date(new Date().toLocaleDateString()).getTime()
    const end = start + 60 * 60 * 24 * 1000
    vm.selectTime[0] = start
    vm.selectTime[1] = end
    this.getData()
  },
  mounted () {
    this.$nextTick(function () {
    })
  },
  computed: {
    // 获得taskid
    taskID () {
      return this.$route.query.id
    }
  },
  watch: {
    // 监听路由变化
    $route (to, from) {
      if (to.fullPath !== from.fullPath) {
        this.getData()
      }
    }
  },
  methods: {
    chartDataSlice () {
      this.chartData.rows = this.tableData.slice(0, 10)
      return this.chartData
    },
    sortChange (value) {
      console.log(value)
      this.tableData.sort((a, b) => {
        return b[value] - a[value]
      })
    },
    exportData () {
      // 将一个table对象转换成一个sheet对象
      const sheet = XLSX.utils.json_to_sheet(this.tableData)
      // 替换表头
      const title = ['用户名', '组号', '完成', '合格', '一次合格', '两次合格', '不合格', '未审核', '合格率', '不合格率']
      const num = 65
      title.forEach((val, index) => {
        const num1 = num + index
        const str = String.fromCharCode(num1) + 1
        sheet[str].v = val
      })
      // 打印xls
      openDownloadDialog(sheet2blob(sheet), `${this.taskID}-用户详情.xlsx`)
    },
    getData () {
      this.loading = true
      const sTime = this.selectTime[0] / 1000
      const eTime = this.selectTime[1] / 1000
      this.tableData = []
      this.dataEmpty = true
      usersDetails(this.taskID, sTime, eTime)
        .then(rsp => {
          this.loading = false
          if (rsp.code === 0) {
            this.tableData = rsp.data.reports.map(item => {
              item.teamid = item.teamid || 0 // 组号
              item.total = item.total || 0 // 总任务
              item.qualified = item.qualified || 0 // 合格任务
              item.oncequalified = item.oncequalified || 0 // 一次合格任务
              item.twicequalified = item.twicequalified || 0 // 两次合格任务
              item.unqualified = item.unqualified || 0 // 不合格任务
              item.notreviewed = item.notreviewed || 0 // 未审核
              item.qualifiedrate = (item.qualified + item.unqualified) !== 0 ? Math.floor(item.qualified / (item.qualified + item.unqualified) * 10000) / 10000 : 0 // 合格率
              item.unqualifiedrate = (item.qualified + item.unqualified) !== 0 ? Math.floor(item.unqualified / (item.qualified + item.unqualified) * 10000) / 10000 : 0 // 不合格率
              return item
            })
            this.sortChange(this.sortValue)
            var time = new Date(rsp.data.updatedAt * 1000)
            this.updatedAt = time.toLocaleString()
            this.dataEmpty = false
          } else if (rsp.code === 1001) {
            if (this.num1001 < 2) {
              this.num1001++
              setTimeout(() => {
                this.getData()
              }, 20000) // 20s 后重试
            } else {
              this.num1001 = 0
            }
            // 超时，重试 2 次
          } else {
            console.log(rsp.message)
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>

<style scoped>
</style>
